//DROP RIGHT
.shape__right {
  position: absolute;
  right: 0;
  height: 1202px;
  width: 737px;
  background-size: cover;
  z-index: -1;
  top: -640px;
  @media screen and (max-width: 1500px) and (min-width: 992px){
    height: 900px;
    width: 500px;
    top: -760px;
  }
  @media screen and (max-width: 991px) {
    width: 0;
  }
  svg{
    max-width: 100%;
  }
}