.video__playpause {
  background-image: url('/assets/icons/Play_button_x2.png');
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  display: block;
}

.loading {
  background: url(/assets/icons/loader.svg);
  background-repeat: no-repeat;
  width: 40%;
  height: 40%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-size: auto;
  background-position: center;
}

.ads__iphone__wrapper--new-frame {
  .video__playpause {
    width: 26%;
    top: -72px;
  }
}

.final__slick__items {
  .video__wrapper {
    position: relative;

    .video__playpause {
      position: absolute;
      width: 26%;
    }
  }
}

.masonry__item {
  position: relative;
  .video {
    width: 100%;
    line-height: 0;
  }
}