@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap";

@import '_videos';
@import '_drops';

$purple: #7a2081;
$lightPurple: #e4d2e6;
$orange: #ef8f34;
@import '_buttons';
@import "cookie";

body {
  color: $purple;
}

img {
  max-width: 100%;
}

a {
  color: inherit;

  &:hover {
    color: unset;
  }
}

h1,
h2 {
  font-family: raleway, sans-serif;
  font-weight: bold;
  color: $purple;
  font-size: 33px;
  line-height: 36px;
  @media (max-width: 991px) {
    font-size: 21px;
    line-height: 28px;
  }
}

h1 {
  max-width: 490px;
  margin: 0 auto 36px;
  @media (max-width: 991px) {
    margin: 0 auto 26px;
  }
}

h2 {
  margin-bottom: 45px;
}

p {
  font-size: 17px;
  line-height: 23px;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    font-size: 13px;
    line-height: 21px;
  }
}

.color-orange {
  color: $orange;
}

//SLICK

.slick-arrow {
  width: 52px;
  height: 52px;
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  background-color: $lightPurple;
  padding: 10px;
  cursor: pointer;
  transition: .3s;
  z-index: 100;

  &.slick-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

}

.slick-next,
.slick-prev {
  width: 12px;
  height: 19px;
  margin-top: 5px;
  margin-left: 4px;
}

//NAVBAR
.navbar {
  @media (max-width: 991px) {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }

}

.navbar-toggler {
  border: none;
  outline: none !important;
  padding-right: 0;
}

#main-menu-mobile {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: none;

  @media (max-width: 991px) {
    display: block;
    z-index: 100;
  }

  span {
    width: 17px;
    height: 3px;
    position: absolute;
    top: 11px;
    left: 11px;
    background-color: #fff;
    transition: 0.3s ease-in-out;
  }

  span:nth-of-type(2) {
    top: 18px;
  }

  span:nth-of-type(3) {
    top: 25px;
  }

  &.active {
    span {
      width: 20px;
      left: 10px;
    }

    span:nth-of-type(1) {
      top: 19px;
      transform: rotate(45deg);
    }

    span:nth-of-type(2) {
      opacity: 0;
    }

    span:nth-of-type(3) {
      top: 19px;
      transform: rotate(-45deg);
    }
  }
}

.bg-dark {
  background-color: transparent !important;
  @media all and (max-width: 992px) {
    background-color: #000 !important;
  }
}

.navbar-logo {
  max-width: 154px;
  max-height: 64px;
  @media all and (max-width: 991px) {
    max-width: 82px;
    max-height: 35px;
  }
}

.nav-link-img {
  max-width: 12px;
  max-height: 21px;
  @media all and (max-width: 991px) {
    max-width: 20px;
    max-height: 37px;
  }
}

.navbar-nav {
  @media all and (max-width: 992px) {
    height: 100vh;
  }
}

.nav-item {
  padding-left: 30px !important;
  transition: .4s;
  @media all and (max-width: 992px) {
    padding-left: 0 !important;
    margin: 0 auto 20px;
    font-size: 19px;
    font-weight: bold;
  }

  &:hover {
    background: linear-gradient(to right, $purple 20%, $orange 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.nav-item:first-child {
  @media all and (max-width: 992px) {
    margin-top: 20px;
  }
}

//HERO
.hero {
  position: relative;
  top: -130px;
  z-index: -10;
  @media all and (max-width: 991px) {
    position: static;
  }
}

.hero__wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

#hero {
  width: 100%;
  display: block;
  background-color: #fff;
  border: none;
  @media all and (max-width: 991px) {
    margin: 0 auto;
  }

}

//SUBHERO

.sub-hero {
  text-align: center;
  margin-top: -100px;
  @media all and (max-width: 991px) {
    margin-top: 0;
  }
}

.sub-hero__text {
  max-width: 880px;
  margin: 0 auto 40px;
  @media all and (max-width: 991px) {
    max-width: 82%;
    font-size: 15px;
  }
}

//ADS
.ads__wrapper {
  position: relative;
}

.ads {
  text-align: center;
  margin-bottom: 164px;

  .slick-arrow {
    @media screen and (max-width: 992px) {
      top: 615px;
    }
  }
  .our-ads{
    margin: 36px auto 67px;
    @media (max-width: 991px){
      font-size: 24px;
      margin: 20px auto 34px;
    }
  }
}

.ads__slick-next-wrapper {
  @media screen and (max-width: 992px) {
    right: 41%;
  }
  @media screen and (max-width: 768px) {
    right: 37%;
  }
  @media screen and (max-width: 500px) {
    right: 33%;
  }
  @media screen and (max-width: 420px) {
    right: 30%;
  }
  .slick-next{
    margin-left: 5px;
  }
}

.ads__slick-prev-wrapper {
  @media screen and (max-width: 992px) {
    left: 41%;
  }
  @media screen and (max-width: 768px) {
    left: 37%;
  }
  @media screen and (max-width: 500px) {
    left: 33%;
  }
  @media screen and (max-width: 420px) {
    left: 30%;
  }
  .slick-prev{
    margin-left: -1px;
  }
}

.ads__iphone__wrapper {
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 243px;
  height: 520px;
  margin: 0 auto;
}

.ads__iphone__wrapper--fb {
  background-image: url("/assets/images/iphone_fb.png");
}

.ads__iphone__wrapper--twitter {
  background-image: url("/assets/images/iphone_twitter.png");
}

.ads__iphone__wrapper--insta {
  background-image: url("/assets/images/iphone_insta.png");
}

.ads__iphone {
  outline: none;
}

.ads__iphone__video {
  position: absolute;
  left: 14px;
  width: 216px;
  height: 386px;
  top: 27px;
}

.title {
  font-weight: bold;
  display: block;
  margin-top: 30px;
  @media screen and (max-width: 992px) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}


//WHY

.why {
  margin: -300px auto 180px auto;
  padding-top: 300px;
  @media screen and (max-width: 1500px) {
    padding-top: 250px;
  }
  @media screen and (max-width: 991px) {
    padding-top: 180px;
    margin: 10px auto 50px auto;
  }
  &__wrapper {
    position: relative;
    content: "";
    background: url("/assets/images/drop-left.png") no-repeat;
    height: 900px;
    background-size: 47% 100%;
    @media screen and (max-width: 1500px) {
      height: 800px;
    }
    @media screen and (max-width: 991px) {
      background-size: 180% 63%;
      background-position: 84% -43px;
      height: 1000px;
    }
  }
  svg {
    width: 270px;
    @media screen and (max-width: 991px) {
      width: 230px;
    }
    @media screen and (max-width: 450px) {
      width: 210px;
      margin-top: 15px;
    }

    .st0 {
      fill: transparent;
      stroke: #7A2081;
      stroke-width: 0;
    }

    .animation {
      stroke-width: 2;
      stroke-dasharray: 320;
      stroke-dashoffset: 0;
      animation: dash 3s linear;
    }

    @keyframes dash {
      from {
        stroke-dashoffset: 320;
      }
      to {
        stroke-dashoffset: 0;
      }
    }

  }
  &__left {
    color: #fff;
    font-family: 'Raleway', sans-serif;
    @media (max-width: 991px) {
      margin-bottom: 100px;
    }
    @media (max-width: 450px) {
      padding: 0;
    }
    &__wrapper {
      margin-right: 15%;
      margin-top: 70px;
      @media screen and (max-width: 991px) {
        width: 340px;
        margin: 0 auto;
      }
      @media screen and (max-width: 450px) {
        width: 300px;
      }
    }
    &__top {
      position: relative;
      margin-left: 40px;
      @media screen and (max-width: 991px) {
        margin-left: 0;
      }
    }
    &__arrow {
      animation-fill-mode: forwards;
      position: absolute;
      right: -15px;
      top: -145px;
      @media screen and (max-width: 991px) {
        right: 1px;
      }
    }
    &__social {
      display: flex;
      justify-content: space-between;
      margin-left: 135px;
      margin-bottom: -40px;
      @media (max-width: 1200px) {
        margin-left: 58px;
      }
      @media (max-width: 991px) {
        margin-left: 73px;
      }
      @media (max-width: 450px) {
        margin-left: 56px;
      }
    }
    &__text {
      display: flex;
      justify-content: end;
      align-items: initial;
      position: relative;
      &__wrapper {
        display: inline-block;
      }
    }
    &__item {
      display: block;
      font-weight: bold;
      font-size: 23px;
      text-align: right;
      line-height: 26px;
      margin-right: 10px;
      @media (max-width: 450px) {
        font-size: 19px;
        line-height: 23px;
      }
    }
  }
  &__icon {
    width: 40px;
    height: 40px;
    @media screen and (max-width: 991px) {
      width: 30px;
      height: 30px;
    }
    &--insta {
      border-radius: 11px;
      -moz-border-radius: 11px;
    }
    &:nth-child(odd):hover {
      -webkit-box-shadow: 0px 0px 38px 0px rgba(184, 184, 184, 1);
      -moz-box-shadow: 0px 0px 38px 0px rgba(184, 184, 184, 1);
      box-shadow: 0px 0px 38px 0px rgba(184, 184, 184, 1);
    }
  }
}

.why__left__dynamic-percents {
  display: flex;
  justify-content: end;
  align-items: baseline;
  position: relative;
  margin-left: 24%;
  @media (max-width: 1200px) {
    margin-left: 9%;
  }
  @media (max-width: 991px) {
    margin-left: 0;
  }
}

.dynamic-percents__number {
  font-size: 130px;
  font-weight: bold;
  position: relative;
  opacity: 0;
  transition: .3s;
  @media screen and (max-width: 992px) {
    font-size: 120px;
    margin-left: 40px;
  }
  @media (max-width: 450px) {
    font-size: 112px;
  }
}

.dynamic-percents__plus {
  position: absolute;
  top: 82px;
  left: 0;
  margin-right: 30px;
  width: 32px;
  height: 32px;
  opacity: 0;
  transition: .3s;
  @media screen and (max-width: 992px) {
    top: 72px;
    margin-right: 15px;
    left: 5px;
  }
  @media (max-width: 450px) {
    left: -11px;
  }
}


.dynamic-percents__plus:before,
.dynamic-percents__plus:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 10px;
  background-color: #fff;
}

.dynamic-percents__plus:after {
  transform: rotate(-90deg);
}

.dynamic-percents__percents {
  line-height: 1em;
  font-size: 140px;
  font-weight: bold;
  opacity: 0;
  transition: .3s;
  @media (max-width: 450px) {
    font-size: 125px;
  }
}


.why__left__triangle,
.why__left__triangle:after,
.why__left__triangle:before {
  width: 7em;
  height: 7em;
  @media (max-width: 450px) {
    width: 6em;
    height: 6em;
  }
}

.why__left__triangle {
  top: -43%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  border-radius: 20%;
  transform: translateY(50%) rotate(0deg) skewY(30deg) scaleX(.866);
}

.why__left__triangle:after,
.why__left__triangle:before {
  position: absolute;
  background: #fff;
  content: '';
}

.why__left__triangle:before {
  border-radius: 20% 20% 20% 53%;
  transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(.866) translateX(-24%);
}

.why__left__triangle:after {
  border-radius: 20% 20% 53% 20%;
  transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(.866) translateX(24%);
}


.why__why__right__h2 {
  max-width: 330px;
  @media screen and (max-width: 992px) {
    margin-bottom: 30px;
    text-align: center;
    max-width: unset;
  }
}

.why__right__paragraph {
  @media screen and (max-width: 991px) {
    text-align: center;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.why__right__paragraph:first-child {
  margin-bottom: 0;
  @media screen and (max-width: 991px) {
    margin-bottom: 40px;
  }
}

//WORKFLOW

.workflow {
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-top: -50px;
  @media screen and (max-width: 991px) {
    margin-top: 0;
  }
}

.workflow__items {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: relative;
  @media screen and (max-width: 991px) {
    width: auto;
    transition: .5s;
  }
}

.workflow__horizontal-line {
  position: absolute;
  top: 56%;
  transform: translateY(-50%);
  background-color: $purple;
  height: 4px;
  width: 0;
  transition: 2s;
  transform-origin: left;
  @media screen and (max-width: 991px) {
    width: 88%;
  }

  &.aos-animate {
    width: 88%;
  }
}

.workflow__items__button__next,
.workflow__items__button__prev {
  position: absolute;
  cursor: pointer;
  width: 52px;
  height: 52px;
  z-index: 20;
  padding: 10px;
  top: 32%;
  transition: .5s linear;

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

}

.workflow__items__button__next:hover {
  @media screen and (min-width: 992px) {
    padding-left: 20px;
  }
}

.workflow__items__button__prev:hover {
  @media screen and (min-width: 992px) {
    padding-right: 20px;
  }
}

.hide-on-desktop {
  @media all and (min-width: 991px) {
    display: none;
  }
}

.workflow__button_img {
  width: 18px;
  height: 30px;
}

.workflow__items__button__prev {
  left: 15px;
}

.workflow__items__button__next {
  right: 15px;
}

.workflow__item {
  position: relative;
  width: 170px;
  height: auto;
  @media screen and (max-width: 991px) {
    width: 100vw;
  }
}

.workflow__item:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: 50%;
  background-color: #fff;
  border: 3px solid $purple;
  top: 53%;
  transform: translateX(50%);
  border-radius: 50%;
  z-index: 1;
}

.workflow__item__image {
  margin-bottom: 70px;
  width: 150px;
}

.workflow__item__text__wrapper {
  max-width: 280px;
  margin: 0 auto;
}

.workflow__item__title {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  display: inline-block;
  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
}

.workflow__item__text {
  font-size: 15px;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 20px;
  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
}

//FINAL EFFECT

.final-effect {
  text-align: center;
}

.final__triangle2 {
  width: 21px;
  height: 21px;
  margin: 0 auto 50px;
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
}

.final__content__wrapper {
  max-width: 240px;
  margin: 0 auto 40px;
}

.final__paragraph__title {
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  display: inline-block;
  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
}

.final__paragraph__text {
  font-size: 15px;
  line-height: 20px;
  display: inline-block;
  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
}

.final__slick__wrapper {
  margin: 0;
  max-width: 320px;
  background-size: 29px 49px;

  @media screen and (max-width: 567px) {
    max-width: 330px;
  }

  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 991px) {
      top: 385px;
    }
  }
}

.final__slick-next-wrapper {
  right: -80px;
  @media screen and (max-width: 991px) {
    right: 90px;
  }

  &:hover {
    @media screen and (min-width: 992px) {
      padding-left: 20px;
    }
  }
}

.final__slick-prev-wrapper {
  left: -80px;
  @media screen and (max-width: 991px) {
    left: 90px;
  }

  &:hover {
    @media screen and (min-width: 992px) {
      padding-right: 20px;
    }
  }
}

//clients
.clients {
  text-align: center;
  margin-bottom: 30px;
  @media screen and (max-width: 992px) {
    margin-bottom: -40px;
  }
}

.clients__items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 86px;
  justify-content: center;
  @media screen and (min-width: 991px) {
    justify-content: flex-start;
  }
  @media screen and (min-width: 1199px) {
    width: 92%;
  }
}

.clients__item {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 109px;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 33.3%
  }
  @media screen and (min-width: 991px) {
    width: 25%;
    height: 100px;
  }
}

@media screen and (max-width: 767px) {
  .clients__item:nth-child(even) {
    border-right: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .clients__item:nth-child(3n+3) {
    border-right: none;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .clients__item:nth-child(6) {
    border-bottom: none;
  }
}

@media screen and (min-width: 992px) {
  .clients__item:nth-child(5) {
    border-right: none;
  }
}

@media screen and (min-width: 1200px) {
  .clients__item:nth-child(6){
    border-bottom: none;
  }
}

.footer__background {
  color: #fff;
  background: url("/assets/images/footer_bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 398px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 991px) {
    height: 262px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}

.footer__content {
  width: 100%;
  display: flex;
  @media screen and (max-width: 991px) {
    display: block;
  }
}

.footer__items {
  margin: 0 auto 55px;
  padding-left: 0;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 991px) {
    text-align: center;
    justify-content: center;
    margin: 0;
  }
}

.footer__item {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 3px;
  @media screen and (max-width: 991px) {
    margin-bottom: 10px;
  }

  &:after {
    content: ('|');
    padding-left: 3px;
  }
}

.footer__item__fb {
  max-width: 12px;
  max-height: 21px;
  @media screen and (max-width: 991px) {
    max-width: 18px;
    max-height: 34px;
  }
}

.footer__item:first-child {
  @media screen and (max-width: 991px) {
    &:after {
      content: ('');
    }
    flex: 0 0 100%;
    justify-content: center;
  }
}

.footer__item:nth-child(4) {
  @media screen and (max-width: 991px) {
    &:after {
      content: ('');
    }
  }
}

.footer__item:last-child {
  margin-left: 19px;
  @media screen and (max-width: 991px) {
    margin-left: 0;
    flex: 0 0 100%;
    justify-content: center;
    margin-bottom: 15px;
  }

  &:after {
    content: ('');
  }
}

.h1 {
  font-size: 30px;
  font-weight: bold;
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mt-36 {
  margin-top: 36px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mb-67 {
  margin-bottom: 67px;
}

.mb-290 {
  margin-bottom: 290px;
}

.mt-0 {
  margin-top: 0;
}

.ml-92 {
  margin-left: 92px;
}

.mb-6 {
  margin-bottom: 6px;
}

.btn-white {
  z-index: 1;
  background: white;
  color: $purple;
  font-weight: bold;
  border: 3px $purple solid;

  &:hover {
    color: #ef8f34;
  }

  &:active {
    color: #ef8f34;
  }
}

.ads__iphone__wrapper--new-frame {
  background-image: url(/assets/images/iPhone_frame_x2.png);
}

.type-of-ads{
  &__container{
    margin-bottom: 120px;
    @media screen and (max-width: 992px) {
      margin-bottom: 0;
    }
  }
  &__slick-container{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    @media (min-width: 991px) {
      width: 100%;
      margin-bottom: 50px;
    }
    .slick-arrow {
      @media screen and (max-width: 992px) {
        top: 100px;
      }
    }
  }
  &__slick-next-wrapper {
    @media screen and (max-width: 992px) {
      right: 0;
    }
    .slick-next{
      margin-left: 11px;
    }
  }
  &__slick-prev-wrapper {
    @media screen and (max-width: 992px) {
      left: 0;
    }
    .slick-prev{
      margin-left: 8px;
    }
  }
}


.type-of-ads__wrapper {
  width: 100%;
  @media (min-width: 1200px) {
    width: 200px;
  }
  p{
    font-size: 15px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 991px) {
      text-align: left;
      font-size: 16px;
      width: 100%;
    }
  }
  ul{
    font-size: 15px;
    text-align: center;
    list-style-type: none;
    width: 90%;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 991px) {
      width: 100%;
      font-size: 16px;
      padding-left: 20px;
      text-align: left;
      list-style-type: disc;
    }
    li{
      span{
        display: inline;
        margin-right: 5px;
        @media (min-width: 991px) {
          display: none;

        }
      }
    }
  }
}

.type-of-ads-img {
  width: 140px;
  margin: 0 auto;
  display: block;
  @media (min-width: 991px) {
    width: 160px;
  }
}

h3 {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  margin: 29px auto;
  @media (min-width: 991px) {
    font-size: 17px;
    margin: 36px auto 28px;
  }
}

.gallery__items {
  width: 100%;
}

.btn__container {
  display: none;
  @media (min-width: 991px) {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
    background-color: white;
  }
}

.d-none-mobile {
  @media screen and (max-width: 991px) {
    display: none !important;
  }
}

.masonry {
  margin-bottom: 80px;
  margin-top: 150px;
  @media (min-width: 991px) {
    column-count: 6;
    column-gap: 0;
    line-height: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
  &__item {
    width: 100%;
    max-width: 100%;
  }
  .slick-arrow {
    top: -40px;
  }
  &__slick-next-wrapper {
    @media screen and (max-width: 992px) {
      right: 41%;
    }
    @media screen and (max-width: 768px) {
      right: 37%;
    }
    @media screen and (max-width: 500px) {
      right: 33%;
    }
    @media screen and (max-width: 420px) {
      right: 30%;
    }
    .slick-next{
      margin-left: 11px;
    }
  }
  &__slick-prev-wrapper {
    @media screen and (max-width: 992px) {
      left: 41%;
    }
    @media screen and (max-width: 768px) {
      left: 37%;
    }
    @media screen and (max-width: 500px) {
      left: 33%;
    }
    @media screen and (max-width: 420px) {
      left: 30%;
    }
    .slick-prev{
      margin-left: 8px;
    }
  }
}

.gradient__container {
  position: absolute;
  top: -615px;
  width: 100%;
  height: 615px;
  background: rgb(252, 252, 252);
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(252, 252, 252, 1) 30%, rgba(252, 252, 252, 0) 100%);
}

#gallery{
  overflow-y: hidden;
}

.show__less {
  @media (min-width: 991px) {
    margin-bottom: -1500px;
    overflow-x: hidden;
  }
}

.btn-masonry {
  position: absolute;
  top: -110px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.btn-masonry-show{
  top: 0;
  margin-top: 50px;
  margin-bottom: 100px;
}

.btn-masonry-rotate{
  transform: rotate(180deg);
}


.best-video__item {
  border-bottom: 1px solid;
  margin-top: 28px;

  .best-video__para {
    margin-bottom: 15px;
    font-size: 14.5px;
    font-weight: 600;
  }

  .best-video__span {
    width: 15px;
    background-image: url('/assets/icons/left-kopia-2-copy@3x.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    margin-right: 20px;
  }
}

.arrow-bottom{
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: none;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
