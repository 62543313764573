.cookies {
    visibility: hidden;
    opacity: 0;
    z-index: -1000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100%;
    transition-duration: .5s;
    border-top: 1px solid #7a2081;
}

.cookies .content-wrapper {
    color: #7a2081;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px;
    overflow-y: auto;
    max-height: 100%;
    transition-duration: .4s;
    background: #fff;

}

.cookies .content-wrapper p {
    margin: 0 auto 20px;
}

.cookies .content-wrapper a {
    color: #fff;
    margin-left: 20px;
    padding: 9px;
    transition: .3s ease-out;
}

.cookies .content-wrapper a:hover {
    color: hsla(0, 0%, 100%, .5);
}

.cookies .content-wrapper .inner-wrapper {
    max-width: 1140px;
    margin: 0 auto;
}

.cookies .content-wrapper .inner-wrapper .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
}

.cookies.active {
    visibility: visible;
    opacity: 1;
    z-index: 1500;
}
.fs-13{
    font-size: 13px;
    line-height: 17px;
}
@media screen and (max-width: 991px) {
    .fs-13 {
        font-size: 11px;

    }
}