$gradient-start: #e24a13;
$gradient-end: #8f0460;
$purple: #7a2081;
$brown: $lightPurple;
$blue: #1CD3F8;

.btn {
   /* reset */
   -webkit-appearance: none;
   border: 0;
   outline: 0;
   /* styles */
   position: relative;
   background: linear-gradient(45deg, $gradient-start 10%, $gradient-end 90%);
   background-position: 60% 0;
   background-size: 200% 100%;
   padding: 15px 0;
   color: #fff;
   border-radius: 100px;
   transition: .3s;
   font-weight: bolder;
   margin: 0 auto 30px;
   font-size: 15px;
  width: 286px;
  height: 52px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;

   &:hover {
      animation: bruh 3s linear infinite;
      color: #fff;
     .arrow-purple{
       border-color: $orange transparent transparent transparent;
       &.rotated{
         border-color: transparent transparent $orange transparent;
       }
     }
   }
  img{
    width: 9px;
    height: 11px;
    margin-left: 5px;
  }
  .arrow-purple{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: $purple transparent transparent transparent;
    margin-left: 5px;
    margin-top: 1px;
    &.rotated{
      border-width: 0 5px 10px 5px;
      border-color: transparent transparent $purple transparent;
      margin-bottom: 1px;
    }
  }
}

@keyframes bruh {
    0% {
        background-position: 60% 0;
    }
  25% {
    background-position: 100% 0;
  }
    50% {
        background-position: 60% 0;
    }
  75% {
    background-position: 0 0;
  }
    100% {
        background-position: 60% 0;
    }
}

.btn-anchor {
   display: table;
   margin: 25px auto;
   text-decoration: none;
   color: lighten(#222, 10%);
   transition: .3s;

   &:hover {
      color: #fff;
   }
}
//cookies
$angle: 8px;
$angle-o: 4px;

.button__cookies {
  top: 0;
  left: 0;
  transition: all .15s linear 0s;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background-color: $purple;
  color: #fff;
  letter-spacing: 1px;
  box-shadow: -6px 6px 0 $brown;
  text-decoration: none;
  border-radius: 30px;
  @media screen and (max-width: 991px) {
    padding: 5px 10px;
    font-size: 12px;
  }
  &:hover {
    top: 3px;
    left: -3px;
    box-shadow: -3px 3px 0 $brown;

    &::after {
      top: 1px;
      left: -2px;
      width: $angle-o;
      height: $angle-o;
    }

    &::before {
      bottom: -2px;
      right: 1px;
      width: $angle-o;
      height: $angle-o;
    }
  }

  &::after {
    transition: all .15s linear 0s;
    content: '';
    position: absolute;
    top: 2px;
    left: -4px;
    width: $angle;
    height: $angle;
    background-color: $brown;
    transform: rotate(45deg);
    z-index: -1;

  }

  &::before {
    transition: all .15s linear 0s;
    content: '';
    position: absolute;
    bottom: -4px;
    right: 2px;
    width: $angle;
    height: $angle;
    background-color: $brown;
    transform: rotate(45deg);
    z-index: -1;
  }
}

a.btn { position: relative; }

a:active.button__cookies {
  top: 6px;
  left: -6px;
  box-shadow: none;

  &:before {
    bottom: 1px;
    right: 1px;
  }

  &:after {
    top: 1px;
    left: 1px;
  }
}